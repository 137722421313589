












































.nav-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid #EEEEEE;
  justify-content: flex-start;
  position: relative;

  .cal-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 128px;
    position: relative;
    height: 64px;
    margin-left: 8px;
    cursor: pointer;
    background-color: #FAFAFA;
    > img {
      height: 24px;
      width: 24px;
    }

    > p {
      font: 400 14px/20px PingFangSC-Regular,PingFang SC;
      padding: 0 2px 0 4px;
      color: #9A9A9A;
    }

    &:hover {
      >p {
        color: #FAA700;
      }
    }

    .drop-btn {
      height: 16px;
      width: 16px;
      box-sizing: border-box;
      margin-bottom: -2px;
    }
  }
}

.cal-panel:hover {
  .calendar-panel {
    display: inline-flex;
  }
}

.calendar-panel {
  display: none;
  position: absolute;
  padding-top: 13px;
  border-radius: 4px;
  top: 60px;
  left: -110px;
  width: 347px;
  z-index: 999;
}
