







































































































$highlight-txt: #FF6215;
$base-txt: #555;
.match-panel {
  width: 1200px;

  .table-header {
    display: inline-flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    height: 38px;
    background-color: #F8F8F8;

    .txt {
      font-size: 12px;
      font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
      font-weight: 400;
      color: #555555;
      line-height: 17px;
      text-align: center;
    }
    .team-info {
      text-align: center;
    }
  }

  .table-body {
    background-color: #FFFF;
    padding-left: 32px;

    .match-item {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      height: 54px;
      width: 1168px;
      border-bottom: 1px solid #eee;

      .txt {
        font-size: 12px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #555555;
        line-height: 17px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 980px) {
    .match-panel
    {
      width: 100%;
    }
}

.table-header {
  .league-name {
    padding-left: 62px;
    text-align: left;
    width: 178px;
    .txt {
      text-align: left;
    }
  }
  .time {
    width: 150px;
  }
  .status {
    width: 150px;
  }
  .team-info {
    flex-grow: 1;
  }
  .video {
    width: 150px;
  }

}

.table-body {
  .league-name {
    display: inline-flex;
    width: 146px;
    justify-content: flex-start;
    align-items: center;
    .game-icon {
      height: 18px;
      width: 18px;
    }
    .name {
      width: 150px;
      text-align: left !important;
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .time {
    width: 150px;
  }
  .status {
    width: 150px;
  }
  .team-info {
    flex-grow: 1;
  }
  .video {
    width: 150px;
  }
}

.team-info {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  .score-panel {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    width: 90px;
    margin: 0 5px;
    font-size: 12px;
    font-weight: bold;
    .tag {
      font-size: 12px;
      font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
      font-weight: bold;
      color: #555;
      line-height: 17px;
    }
  }
  .name {
    width: 178px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .logo {
    height: 18px;
    width: 18px;
    margin: 0 7px;
  }
}

.table-body .video {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #9a9a9a;
  cursor: pointer;
  position: relative;
  .icon {
    height: 16px;
    width: 16px;
    margin-left: 4px;
    background-image: url("https://qn.tianqifengyun.cn/365live/video_icon_001.png");
    background-size: 16px;
  }

  .pop-panel {
    display: none;
    position: absolute;
    top: -40px;
    right: -109px;

    .pop-content {
      width: 165px;
      height: 29px;
      background: rgba(0,0,0,0.6);
      opacity: 1;
      border-radius: 4px;
      position: relative;

      .tag-txt {
        font-size: 12px;
        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 29px;
      }
      .tir {
        display: inline-block;
        position: absolute;
        height: 8px;
        width: 8px;
        border: 8px solid transparent;
        border-top: 8px solid rgba(0,0,0,0.6);
        bottom: -16px;
        left: 4px;
      }
    }
  }

  &:hover {
    .pop-panel {
      display: inline-block;
    }
  }
}

.table-body .live-panel {
  color: $highlight-txt !important;
  .icon {
    background-image: url("https://qn.tianqifengyun.cn/365live/video_icon_002.png") !important;
  }
}

.highlight-txt {
  color: $highlight-txt !important;
}

.empty-panel {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 400px;
  padding-top: 100px;

  .icon {
    height: 210px;
    width: 270px;
    margin-bottom: 10px;
    position: relative;
    left: -10px;
  }
  .txt {
    font-size: 16px;
    color: #9a9a9a;
  }
}
