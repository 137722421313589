


















.foot-wrapper {
  width: 100%;
  height: 107px;
  background-color: #1f1f1f;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 1200px;
    height: 100%;
    margin: auto;
  }
}

.content .txt {
  width: 100%;
  height: 36px;
  text-align: left;
  font-size: 12px;
  font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
}

.content .more-link {
  position: relative;
  width: 100%;

  .right-txt {
    display: inline-block;
    position: absolute;
    text-align: right;
    right: 0;
    top: 0;
    padding-right: 156px;
  }
}
