









































$QINIU_LINK: 'https://qn.tianqifengyun.cn/pcweb/';
.game-type-panel {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 50px;

  .type-item {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    max-width: 88px;
    border-right: 1px solid #eee;
    cursor: pointer;
    &:first-child {
      margin-left: 13px;
    }
    &:last-child {
      border-right: none;
    }

    &:hover {
      >p {
        color: #FAA700;
        font-weight: 500;
      }
    }

    >div {
      height: 18px;
      width: 18px;
    }
    >p {
      display: inline-block;
      text-align: center;
      padding-left: 4px;
      font: 400 12px/17px PingFangSC-Regular,PingFang SC;
      color: #9a9a9a;
    }
  }

  .active-all {
    >p {
      color: #E59900;
      font-weight: 500;
    }
  }

  %bgr {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 18px;
  }

  .game-bb {
    >div {
      @extend %bgr;
      background-image: url('#{$QINIU_LINK}icon_game_type_new_bb.png');
    }
    &:hover {
      p {
        color: #FAA700;
      }
    }
  }
  .game-fb {
    >div {
      @extend %bgr;
      background-image: url('#{$QINIU_LINK}icon_game_type_new_fb.png');
    }
    &:hover {
      p {
        color: #FAA700;
      }
    }
  }

  .active-bb {
    div {
      background: url('#{$QINIU_LINK}icon_game_type_new_bb.png') no-repeat center;
      background-size: 18px;
    }
    p {
      color: #E59900 !important;
      font-weight: 500;
    }
  }

  .active-fb {
    div {
      background: url('#{$QINIU_LINK}icon_game_type_new_fb.png') no-repeat center;
      background-size: 18px;
    }
    p {
      color: #E59900 !important;
      font-weight: 500;
    }
  }

}
