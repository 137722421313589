











































































.home-wrapper {
  margin: 0 auto;
}
.body-panel{
  width: 100%;
  min-height: calc(100vh - 170px);
  background-color: #F0F2F5;

  .body-content {
    width: 1200px;
    margin: 10px auto 18px;
    min-height: calc(100vh - 198px);
    background-color: #fff;
  }
}

@media screen and (max-width: 980px) {
  .body-panel {
    .body-content {
      width: 100%;
    }
  }
}

