























































































@mixin verticalCenter-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin verticalCenter-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin base-font {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: 400 12px/17px PingFangSC-Regular,PingFang SC;
}

.calc-panel {
  @include verticalCenter-row;
  height: 100%;
  flex-grow: 1;
  .date-item {
    @include verticalCenter-col;
    width: 150px;
    height: 48px;
    overflow: auto;
    cursor: pointer;
    &:hover {
      p {
        color: #E59900;
      }
    }

    .date-panel {
      @include verticalCenter-col;
      position: absolute;
      height: 68px;
      width: 108px;
      border-radius: 8px;
    }

    p {
      @include base-font;
      font-size: 14px;
      color: #9A9A9A;
    }

    &:last-child {
      border-right: none;
    }
  }
  .dateActive {
    p {
      font-weight: bold;
      color: #FFFFFF;
    }

    &:hover {
      p {
        color: #FFFFFF !important;
      }
    }

    .date-panel {
      background-color: #E59900;
    }
  }
}
